//
// Color system
//

$white: #fff !default;
$gray-100: #f9f9f9 !default;
$gray-200: #f3f4f4 !default;
$gray-300: #ededed !default;
$gray-400: #cbc8d0 !default;
$gray-500: #adb5bd !default;
$gray-600: #839195 !default;
$gray-700: #444b4c !default;
$gray-750: #35393a !default;
$gray-800: #0c323e !default;
$gray-900: #17141f !default;
$black: #000 !default;

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #593196 !default;
$pink: #e83e8c !default;
$red: #fc3939 !default;
$orange: #fd7e14 !default;
$yellow: #efa31d !default;
$green: #13b955 !default;
$teal: #50e3c2 !default;
$cyan: #009cdc !default;

$primary: #24bbec !default;
$secondary: #c3c9ca !default;
$success: $teal !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-200 !default;
$dark: $gray-700;

$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;

// Default text color
$body-color: $gray-700;

// Make dropdown menues appear over sticky elements
$zindex-dropdown: 1021;

$dropdown-header-color: $gray-100;
$dropdown-item-padding-y: 0.5rem;
$dropdown-item-padding-x: 1rem;

// thinner close cross
$close-font-weight: 100;

// Alerts
$alert-border-radius: 0;
$alert-bg-level: 5;
$alert-border-level: 0;
$alert-color-level: -10;
