@import 'styles/variables';

.GuestList {
  margin-bottom: 7rem;

  .GuestTicket {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.2rem; //h5

    @media (min-width: 576px) {
      font-size: 1.75rem; //h3
    }
  }

  .Guest__CheckInAll {
    border-radius: 1000px;
  }
}

.GuestList__Header {
  background-color: $gray-100;
  z-index: 1020;
}
