@import './styles/variables';
@import 'bootstrap/scss/bootstrap.scss';
@import './styles/theme';

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.btn-primary {
  color: white;
  &:disabled {
    color: white;
    opacity: 0.75;
  }
  &:hover {
    color: white;
  }
}
.btn-outline-primary:hover {
  color: white;
}

body.LoggedIn {
  background-color: $gray-100;
}

.LoggedInHeader {
  background-color: $gray-200;
}

.cursor-pointer {
  cursor: pointer;
}
