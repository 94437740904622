@import 'styles/variables';

// Override or extend bootstrap components here

// dropdowns
.dropdown-header {
  background-color: $gray-700;
  margin-top: -0.5rem;
}

// This is similar to bootstrap's dropdown-menu-right/dropdown-menu-left
// Unfortunately it doesn't define anything to cover the dropdown menu.
.dropdown-menu-over {
  right: 0;
  top: 0;
  left: auto;
}

// Close icons on modals
.close {
  padding: 0;
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  float: right;
  font-size: 1.5rem;
  font-weight: 100;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  &:hover {
    opacity: 0.75;
  }
}

// Badges with number of notes
.badge-primary {
  color: white;
  background-color: $primary;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition:
    color 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

//Ticket row styling
.mr-2,
.mx-2 {
  margin-right: 0.5rem;
}

//Styling for form modals
.mb-4 {
  margin-bottom: 1.5rem;
}

label {
  margin-bottom: 0.5rem;
}

.form-group {
  margin-bottom: 1rem;
}

.form-input-area {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.form-input-area:focus {
  border-color: #92ddf6;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(36, 187, 236, 0.25);
}
