.CheckInStats {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: fit-content;
}

.PackageCheckIn {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;
  padding: 0px 3px;
  margin: 2px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &--inactive {
    opacity: 50%;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }

  &__Title,
  &__Count,
  &__Percent {
    text-align: center;
  }

  &__Title {
    width: 100%;
    font-size: 0.8em;
    opacity: 0.9;
    padding: 0 0.5em;
  }

  &__Count,
  &__Percent {
    white-space: nowrap;
    flex: 0 0 50%;
  }
}
