@import 'styles/variables';

.SyncStatusIndicator {
  flex-grow: 1;
  padding: 2px 0.5rem;
  margin: 8px;
  border-radius: 5px;
  color: white;
  background-color: $teal;

  &--pending {
    background-color: $pink;
    cursor: pointer;
  }

  &--syncing {
    background-color: $cyan;
  }

  .LoadingSpinner {
    display: inline-block;
    margin-right: 0.5rem;

    @media (min-width: 768px) {
      margin: 0 0.5rem;
    }

    .spinner-border {
      color: white !important;
      height: 15px;
      width: 15px;
      margin: 0 !important;
    }
  }
}
